import React, { useCallback, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

import { Button } from '@genericComponents/Button/Button'
import { Icon } from '@genericComponents/Icon/Icon'

import {
  Container,
  InnerWrap,
  Navigation,
  NavigationInner,
  NavigationSearch,
  NavigationZoom,
  SearchBar,
  SearchBarInner,
} from './PdfViewer.styles'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

interface PdfViewerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any
  documentClassName?: string
  pageClassName?: string
  pageBackground?: string
  canvasWidth?: number
  viewerWidth?: string
  viewerHeight?: string | number
  // StyleProps
  containerHeight?: string | number
  containerPadding?: string
  containerBorder?: string
  // navigation bar
  navigationTop?: string
  navigationLeft?: string
  navigationRight?: string
  navigationBottom?: string
  navigationBgColor?: string
  navigationBorderRadius?: string
  navigationPadding?: string
  navigationBoxShadow?: string
  navigationButtonBgColor?: string
  navigationButtonBorder?: string
  navigationButtonColor?: string
  navigationButtonBgColorHover?: string
  navigationButtonBorderHover?: string
  navigationButtonColorHover?: string
  navigationButtonPadding?: string
  navigationButtonColorDisabled?: string
  navigationButtonBgColorDisabled?: string
  navigationButtonBorderDisabled?: string
  navigationSpacerColor?: string
  // search bar
  searchTop?: string
  searchLeft?: string
  searchRight?: string
  searchBottom?: string
  searchBgColor?: string
  searchBorderRadius?: string
  searchPadding?: string
  searchBoxShadow?: string
  searchColor?: string
  searchFontSize?: string
  searchInputRadius?: string
  searchInputHeight?: string
  searchInputColor?: string
  searchInputBgColor?: string
  searchInputBgColorFocus?: string
  searchInputBorder?: string
  searchInputFocusBorder?: string
  searchInputFocusOutline?: string
  searchInputPadding?: string
  initialScale?: number
  scrollTrackColor?: string
  scrollHandleColor?: string
  scrollRadius?: string
  onScrollHandler?: React.UIEventHandler<HTMLDivElement>
}

function highlightPattern(text, pattern) {
  return text.replace(pattern, (value) => `<mark>${value}</mark>`)
}

export const PdfViewer = ({
  file,
  documentClassName,
  pageClassName,
  pageBackground,
  canvasWidth,
  viewerWidth,
  viewerHeight,
  containerHeight,
  containerPadding,
  containerBorder,
  navigationTop,
  navigationLeft,
  navigationRight,
  navigationBottom,
  navigationBgColor,
  navigationBorderRadius,
  navigationPadding,
  navigationBoxShadow,
  navigationButtonBgColor,
  navigationButtonBorder,
  navigationButtonColor,
  navigationButtonBgColorHover,
  navigationButtonBorderHover,
  navigationButtonColorHover,
  navigationButtonPadding,
  navigationSpacerColor,
  navigationButtonColorDisabled,
  navigationButtonBgColorDisabled,
  navigationButtonBorderDisabled,
  searchTop,
  searchLeft,
  searchRight,
  searchBottom,
  searchBgColor,
  searchBorderRadius,
  searchPadding,
  searchBoxShadow,
  searchColor,
  searchFontSize,
  searchInputRadius,
  searchInputHeight,
  searchInputColor,
  searchInputBgColor,
  searchInputBgColorFocus,
  searchInputBorder,
  searchInputFocusBorder,
  searchInputFocusOutline,
  searchInputPadding,
  initialScale,
  scrollTrackColor,
  scrollHandleColor,
  scrollRadius,
  onScrollHandler,
}: PdfViewerProps) => {
  const [numPages, setNumPages] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [pageScale, setPageScale] = useState(1.0)
  const [searchVisibility, setSearchVisibility] = useState(false)

  const navButtonProps = {
    bgColor: navigationButtonBgColor,
    border: navigationButtonBorder,
    color: navigationButtonColor,
    bgColorHover: navigationButtonBgColorHover,
    borderHover: navigationButtonBorderHover,
    colorHover: navigationButtonColorHover,
    padding: navigationButtonPadding,
    colorDisabled: navigationButtonColorDisabled,
    bgColorDisabled: navigationButtonBgColorDisabled,
    borderDisabled: navigationButtonBorderDisabled,
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const textRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, searchText),
    [searchText]
  )

  function onChange(event) {
    setSearchText(event.target.value)
  }

  function toggleSearch() {
    setSearchVisibility(!searchVisibility)
  }

  function renderPages() {
    // eslint-disable-next-line prefer-const
    let pages = []

    for (let i = 0; i < numPages; i++) {
      pages.push(
        <Page
          key={`page--${i}`}
          className={pageClassName}
          pageNumber={i + 1}
          customTextRenderer={textRenderer}
          canvasBackground={pageBackground}
          width={canvasWidth}
          scale={pageScale}
        />
      )
    }

    return <>{pages}</>
  }

  useEffect(() => {
    if (initialScale) {
      setPageScale(initialScale)
    }
  }, [])

  return (
    <Container
      viewerWidth={viewerWidth}
      viewerHeight={containerHeight || viewerHeight}
      border={containerBorder}
      padding={containerPadding}
    >
      <InnerWrap
        viewerWidth={viewerWidth}
        viewerHeight={viewerHeight}
        scrollTrackColor={scrollTrackColor}
        scrollHandleColor={scrollHandleColor}
        scrollRadius={scrollRadius}
        onScroll={onScrollHandler ? onScrollHandler : undefined}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className={documentClassName}
        >
          {renderPages()}
        </Document>
      </InnerWrap>
      <Navigation
        top={navigationTop}
        left={navigationLeft}
        right={navigationRight}
        bottom={navigationBottom}
      >
        <NavigationInner
          bgColor={navigationBgColor}
          borderRadius={navigationBorderRadius}
          padding={navigationPadding}
          boxShadow={navigationBoxShadow}
        >
          <NavigationZoom borderColor={navigationSpacerColor}>
            <Button
              onClick={() => setPageScale(pageScale - 0.2)}
              {...navButtonProps}
            >
              <Icon
                color={navigationButtonColor}
                colorHover={navigationButtonColorHover}
                type="minus-square"
                size="20px"
              />
            </Button>

            <Button onClick={() => setPageScale(1.0)} {...navButtonProps}>
              100%
            </Button>

            <Button
              onClick={() => setPageScale(pageScale + 0.2)}
              {...navButtonProps}
            >
              <Icon
                color={navigationButtonColor}
                colorHover={navigationButtonColorHover}
                type="plus-square"
                size="20px"
              />
            </Button>
          </NavigationZoom>
          {/* <NavigationPages borderColor={navigationSpacerColor}>
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              {...navButtonProps}
            >
              <Icon
                color={navigationButtonColor}
                colorHover={navigationButtonColorHover}
                type="arrow-left"
                size="20px"
              />
              Previous
            </Button>
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <Button
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              {...navButtonProps}
            >
              Next
              <Icon
                type="arrow-right"
                size="20px"
                color={navigationButtonColor}
                colorHover={navigationButtonColorHover}
              />
            </Button>
          </NavigationPages> */}

          <NavigationSearch>
            <Button onClick={toggleSearch} {...navButtonProps}>
              <Icon
                type="search"
                size="20px"
                color={navigationButtonColor}
                colorHover={navigationButtonColorHover}
              />
            </Button>
          </NavigationSearch>
          {/* Add button with search icon which shows the search bar */}
        </NavigationInner>
      </Navigation>

      {searchVisibility ? (
        <SearchBar
          top={searchTop}
          left={searchLeft}
          right={searchRight}
          bottom={searchBottom}
        >
          <SearchBarInner
            bgColor={searchBgColor}
            borderRadius={searchBorderRadius}
            padding={searchPadding}
            boxShadow={searchBoxShadow}
            color={searchColor}
            fontSize={searchFontSize}
            inputRadius={searchInputRadius}
            inputHeight={searchInputHeight}
            inputColor={searchInputColor}
            inputBgColor={searchInputBgColor}
            inputBgColorFocus={searchInputBgColorFocus}
            inputBorder={searchInputBorder}
            inputFocusOutline={searchInputFocusOutline}
            inputFocusBorder={searchInputFocusBorder}
            inputPadding={searchInputPadding}
          >
            <input
              type="search"
              id="search"
              value={searchText}
              onChange={onChange}
            />
          </SearchBarInner>
        </SearchBar>
      ) : (
        <></>
      )}
    </Container>
  )
}

import { ContentContainer, LoadingSpinner } from '../components/Generic'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'

import { Intro } from '../components/ResumeFromEmail/steps/IntroPage'
import { Otp } from '../components/ResumeFromEmail/steps/OtpPage'
import { Error } from '../components/ResumeFromEmail/steps/ErrorPage'

import { devLog } from '../methods/devLog'

export interface GetResumeFromEmailContextResponse {
  maskedPhoneNumber: string
  sendResumptionEndpoint: string
  enterResumptionEndpoint: string
  otpMaxAttempts: number
  smsMaxAttempts: number
  smsRetrySeconds?: number
}

export const ResumeFromEmailPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const apiUrl = process.env.WEB_API_URL

  const [step, setStep] = useState(0)
  const [context, setContext] = useState<
    GetResumeFromEmailContextResponse | undefined
  >()

  const nextStep = () => {
    setStep(step + 1)
  }

  const initComponent = async () => {
    try {
      const { data } = await axios.get<GetResumeFromEmailContextResponse>(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/get-resume-transaction-context`,
        {
          withCredentials: true,
        }
      )

      devLog(data)
      setContext(data)

      nextStep()
    } catch (error) {
      devLog(error)
    }
  }

  useEffect(() => {
    devLog('initComponent')
    void initComponent()
  }, [])

  const renderCurrentStep = () => {
    if (!context)
      return (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      )

    switch (step) {
      case 0:
        return (
          <>
            <LoadingSpinner
              width="68px"
              padding="100px 0"
              height="400px"
              {...theme.loadingSpinner}
            />
          </>
        )
      case 1:
        return (
          <Intro
            nextStep={nextStep}
            phoneNumber={context.maskedPhoneNumber}
            sendOtpUrl={context.sendResumptionEndpoint}
          />
        )
      case 2:
        return (
          <Otp
            nextStep={nextStep}
            maxAttempts={context.otpMaxAttempts}
            verifyOtpUrl={context.enterResumptionEndpoint}
            sendOtpUrl={context.sendResumptionEndpoint}
            smsMaxAttempts={context.smsMaxAttempts}
            smsRetrySeconds={context.smsRetrySeconds}
            isSmsEnabled={true}
          />
        )
      case 3:
        return <Error />

      default:
        return <></>
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {renderCurrentStep()}
    </ContentContainer>
  )
})

import styled, { css } from 'styled-components'

export const ContentRouterWrap = styled.div<{
  showBackgroundImage?: boolean
  backgroundImage?: string
}>`
  ${({ showBackgroundImage, backgroundImage }) =>
    showBackgroundImage &&
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      min-height: 100vh;
    `}
`

export const ContentWrap = styled.div`
  width: 1000px;
  margin: 0 auto;
  padding-top: ${(props) => (props.iframeContent ? `0` : '60px')};

  @media (max-width: 980px) {
    width: 100%;
    min-width: auto;
    margin: 0;
    padding: 0;

    > div {
      height: calc(100vh - 120px);
      position: relative;
      border-bottom: none;
      border: none;
      box-shadow: none;
    }
  }

  button {
    transition: all 0.2s ease-in-out;
  }

  a {
    font-weight: 700;
  }
`

export const ContentContainerBottomLeft = styled.div`
  font-size: 12px;
  width: 100%;
`

export const ContentContainerBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    position: absolute;
    /* position: fixed; */
    padding: 30px 20px 20px;
    bottom: 1px;
    left: 0;
    width: 100%;
    /* background-color: #fff; */
  }
`

export const ContentContainerBottomUpload = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    position: absolute;
    padding: 30px 20px 20px;
    bottom: 1px;
    left: 0;
    width: 100%;
  }
`

export const MobileSpacerForContentContainerBottomFixed = styled.div<{
  margin: string
}>`
  display: none;
  @media (max-width: 980px) {
    display: block;
    width: 100%;
    height: 100px;
    margin: ${(props) => (props.margin ? props.margin : '10px 0')};
  }
`

export const ContentContainerWithScroll = styled.div`
  @media (max-width: 980px) {
    height: ${(props) =>
      props.mobileContainerHeight
        ? `${props.mobileContainerHeight}  !important`
        : 'calc(100vh - 120px) !important'};
    position: relative;
    border-bottom: none;
    border: none;
    box-shadow: none;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      background-color: white;
    }

    ::-webkit-scrollbar-thumb {
      background: white;
    }
  }
`

export const ContentContainerBottomFixed = styled.div<{
  mobileGap: string
  borderTop: string
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : '1px solid #ebebeb'};
  padding-top: 30px;
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: 980px) {
    background-color: #fff;
    position: fixed;
    padding: 30px 20px 20px;
    margin-bottom: 0%;
    bottom: 0px;
    left: 0;
    width: 100%;
    gap: ${(props) => (props.mobileGap ? props.mobileGap : '0px')};
  }
`

export const ContentContainerBottomCentered = styled.div<{
  border: string
  padding: string
  margin: string
  gap: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  border-top: ${(props) => (props.border ? props.border : '1px solid #ebebeb')};
  padding: ${(props) => (props.padding ? props.padding : '26px 0 0 0')};
  box-sizing: border-box;
  gap: ${(props) => (props.gap ? props.gap : '0px')};
  align-items: center;

  @media (max-width: 980px) {
    position: absolute;
    padding: 26px 20px 20px;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-top: none;
  }
`

export const ContentContainerBottomFixedHorizontal = styled.div<{
  mobileGap: string
  border: string
}>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  border-top: ${(props) => (props.border ? props.border : '1px solid #ebebeb')};
  padding-top: 30px;
  box-sizing: border-box;
  z-index: auto;

  & > *:only-child {
    margin-left: auto;
  }

  @media (max-width: 980px) {
    background-color: #fff;
    position: fixed;
    padding: 30px 20px 20px;
    margin-bottom: 0%;
    bottom: 0px;
    left: 0;
    width: 100%;
    gap: ${(props) => (props.mobileGap ? props.mobileGap : '0px')};
  }
`

export const ContentContainerBottomCenteredIkano = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 26px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    position: absolute;
    padding: 26px 20px 20px;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-top: none;
  }
`

export const VerticallyCenteredContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const VerticallyCenteredContainerIkano = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentContainerBottomCenteredKlarna = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  border-top: 1px solid #ebebeb;
  padding-top: 26px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    position: absolute;
    padding: 26px 20px 20px;
    bottom: -60px;
    left: 0;
    width: 100%;
    border-top: none;
  }
`

export const CenteredContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : '0')};
`

export const ContentToRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const PageWrap = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.width ? props.width : '560px')};
`

export const Margined = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0')};
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const MarginedWithHeight = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '0')};
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: ${(props) => (props.height ? props.height : 'auto')};
`

export const MarginOnly = styled.span`
  margin: ${(props) => (props.margin ? props.margin : '0')};
  display: ${(props) => (props.display ? props.display : 'inline-block')};

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`

export const MobileFloatingConfirmButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ebebeb;

  button {
    margin: 0 20px;
  }
`

export const CenteredContentColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '0')};

  & > p,
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    text-align: center;
  }
`

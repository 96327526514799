import { ContentContainer, Button, Header } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { forceRedirect } from '../methods/forceRedirect'
import { ButtonText, ParagraphCentered } from '../styles/generic.styles'
import {
  ContentContainerBottomCentered,
  VerticallyCenteredContainer,
} from '../styles/layout.styles'
import { CompletePageIcon } from '../pages/ikano/CompletePageIcon'

export const SaltedgeReturnPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState
  const { theme } = store.InterfaceState
  const { saltEdge: trans } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const location = window.location.href
  const url = new URL(location)

  const handleProceed = async () => {
    setIsDisabled(true)
    forceRedirect(`${process.env.WEB_API_URL}/salt-edge/complete${url.search}`)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      {!mobileVersion ? (
        <>
          <CompletePageIcon
            fillColor={theme.icon.color2}
            backgroundColor={theme.icon.backgroundColor ?? 'white'}
          />
          <Header>{trans.success}</Header>
          <ParagraphCentered>{trans.message}</ParagraphCentered>
        </>
      ) : (
        <VerticallyCenteredContainer>
          <CompletePageIcon
            fillColor={theme.icon.color2}
            backgroundColor={theme.icon.backgroundColor ?? 'white'}
          />
          <Header>{trans.success}</Header>
          <ParagraphCentered>{trans.message}</ParagraphCentered>
        </VerticallyCenteredContainer>
      )}
      <ContentContainerBottomCentered border="0">
        <Button
          disabled={isDisabled}
          onClick={handleProceed}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
          id="page-submit"
          type="submit"
        >
          <ButtonText>{trans.continue}</ButtonText>
        </Button>
      </ContentContainerBottomCentered>
    </ContentContainer>
  )
})

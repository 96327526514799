import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './components/App'
import { devLog } from './methods/devLog'
import { configureAwsRum } from './methods/awsRum'

configureAwsRum()

if (process.env.NODE_ENV !== 'production') {
  devLog('DEV MODE')
}

if (process.env.NODE_ENV === 'production') {
  devLog('*****PRODUCTION BUILD*****')
}

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(<App />)
